import dayjs from 'dayjs';
import * as React from 'react';
import { METHOD_TABLE } from '../../../const';
import { toBR } from '../../../lib';
import { Shipping } from '../../../types';

interface PickingListHeaderProps {
	shipping: Shipping;
}

export const PickingListHeader: React.FC<PickingListHeaderProps> = (props) => {
	const { method, orders } = props.shipping;
	const order = orders[0];
	const orderMethod = METHOD_TABLE[order.method] || order.method;

	const fontSizeClass = (size: number) => {
		return [
			'x2','x2','x2','x2','x2',	// 0〜4
			'x2','x2','xl','xl','xl',	// 5〜9
			'lg','lg','md','md','md',	// 10〜14
		][size] || 'x2';
	};

	const deliveryDateOpt = (delivery_date: Record<string, string>) => {
		let opt;
		if (delivery_date.asuraku) opt = '(きょうつく)';
		if (delivery_date.kyotsuku) opt = '(きょうつく)';
		if (delivery_date.asutsuku) opt = '(あすつく)';
		return opt ? <span className="xs"> { opt }</span> : '';
	}

	let delivery_date;
	if (order.method === 'amazon') {
		try {
			delivery_date = JSON.parse(props.shipping.delivery_date ?? '');
		} catch (e) {
			delivery_date = { date: props.shipping.delivery_date, timezone: "", shipping: "" };
		}
	} else {
		delivery_date = order.delivery_date;
	}

	return (
		<header>
			<div className="title">受注明細票</div>
			<div className="date">
				<dl>
					<dt>発送日</dt><dd><p className="lg">{ delivery_date.shipping }</p></dd>
					<dt>届け日時指定</dt><dd><p className="lg bold">
						{ delivery_date.date || '届け日指定なし' }{ deliveryDateOpt(delivery_date) }<br />
						{ delivery_date.timezone || '届け時間帯指定なし' }
					</p></dd>
					{order.detail.specifiedDeliveryDate && <>
						<dt>配送日時指定</dt><dd><p className="lg strong">{ toBR(order.detail.specifiedDeliveryDate) }</p></dd>
					</>}
				</dl>
			</div>
			<div className="info">
				<div className="shippingMethod"><p className={ `${fontSizeClass(method.length)} bold` }>{ method }</p></div>
				<div className="mall"><p className={ `${fontSizeClass(orderMethod.length)} bold` }>{ orderMethod }</p></div>
				<div className="orderInfo lg">
					<dl>
						<dt>購入日時</dt><dd className="bold">{ dayjs(order.orderEpoch).format('YYYY/MM/DD  HH:mm') }</dd>
						<dt>受注番号</dt><dd>{ order.idtext }</dd>
					</dl>
				</div>
			</div>
		</header>
	);
};