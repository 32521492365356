import { QRCodeCanvas } from "qrcode.react";
import React from "react";

interface QRCodeProps {
	url: string;
	isIncludeMargin: boolean;
}

export const QRCode: React.FC<QRCodeProps> = (props) => {
	return (
		<QRCodeCanvas
			value={props.url}
			size={64}
			bgColor={"#ffffff"}
			fgColor={"#000000"}
			level={"L"}
			includeMargin={props.isIncludeMargin}
			imageSettings={{
				src: "",
				x: undefined,
				y: undefined,
				height: 24,
				width: 24,
				excavate: true,
			}}
		/>
	);
}
export default QRCode;