import { sasagase } from "@sasagase/types";
import * as React from 'react';
import { toBR } from '../../../lib';
import { QRCode } from '../../molecules/QRCode';
import { ShippingOrderItemAlpha } from './PickingList';

interface ItemListRowProps {
	itemType: 'parent' | 'child'
	item: ShippingOrderItemAlpha;
	prefix: string;
	settings: sasagase.GetOrderInstructionSetting[];
}

export const ItemListRow: React.FC<ItemListRowProps> = (props) => {
	const { itemType, item, prefix, settings } = props;
	const isTarget = Boolean(item.locationId);
	const name = item.name_s ?? item.name;
	const source = item.source || [];
	// 個数が2個以上、もしくは直接出荷しない親商品などは強調表示
	const quantityClass = !isTarget ? 'strong x2' :
		item.quantity > 1 ? 'strong lg' : '';
	const quantity = <span className={quantityClass}>{item.quantity}</span>;
	const valueClass = (key: string) => {
		return settings?.some(setting => 
			(setting.matchType === "exact" && setting.keyword === key) ||
			(setting.matchType === "partial" && key.includes(setting.keyword))
		) ? 'strong x2' : '';
	};
	const poNumber = item.purchaseOrderExports?.map((purchaseObj) => {
		return purchaseObj.purchaseOrder?.poNumber ?? '';
	}).join("\n") || "";

	return (
		<tr>
			<td className="textC">{item.idx}</td>
			<td className="textC">{prefix}{item.sku}</td>
			<td>
				<p className={itemType === 'parent' ? 'itemName' : ''}>{name}</p>
				{(source.length > 0 && itemType === 'parent') &&
					<p className="itemOptions">
						{source.map(s => <React.Fragment key={s.key}>{s.key}:<span className={valueClass(s.key)}>{s.value}</span><br /></React.Fragment>)}
					</p>
				}
			</td>
			<td className="textR">{quantity}</td>
			<td>
				{itemType === 'parent' && <>
					{toBR(poNumber)}
					{item.itemUrl && <QRCode url={item.itemUrl} isIncludeMargin={true} />}
				</>}
			</td>
		</tr>
	);
};