import { sasagase } from "@sasagase/types";
import * as React from 'react';
import { getOrderInstructionSettings } from "../api";
import { useAPI } from "../context";

export function useStore(): sasagase.GetOrderInstructionSetting[] {
	const callAPI = useAPI();

	const [settings, setSettings] = React.useState<sasagase.GetOrderInstructionSetting[]>([]);

	React.useEffect(() => {

		const load = async () => {
			const result = await callAPI(getOrderInstructionSettings());
			if (result.data) {
				setSettings(result.data);
			}
		};

		load();
	}, []);

	return settings;
}
export default useStore;
