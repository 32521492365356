type KeySelector<T, K> = (element: T, index: number) => K;

export function groupMap<T, K>(items: T[], keySelector: KeySelector<T, K>): Map<K, T[]>;
export function groupMap<T, KL extends K, K>(items: T[], keySelector: KeySelector<T, K>, keys: readonly KL[]): Map<KL, T[]> & Map<K, T[]>;
export function groupMap<T, KL extends K, K>(items: T[], keySelector: KeySelector<T, K>, keys: readonly KL[] = []): Map<KL, T[]> & Map<K, T[]> {
	const map = new Map<K, T[]>(keys.map(key => [key, []]));
	for (const [i, elem] of items.entries()) {
		const key = keySelector(elem, i);
		
		let array = map.get(key);
		if (!array) {
			array = [];
			map.set(key, array);
		}
		array.push(elem);
	}
	return map as Map<KL, T[]> & Map<K, T[]>;
}
export default groupMap;
