import { sasagase } from "@sasagase/types";
import * as React from 'react';
import { Shipping, ShippingOrderItem } from '../../../types';
import { Barcode } from '../Barcode';
import { ItemListRow } from './ItemListRow';
import { PickingListHeader } from './PickingListHeader';
import { PickingListShipping } from './PickingListShipping';

export type ShippingOrderItemAlpha = ShippingOrderItem & {
	id: number;
	parentId?: number;
	idx?: number;
};

interface ItemGroup {
	parent: ShippingOrderItemAlpha;
	items: ShippingOrderItemAlpha[];
}

export interface PickingListProps {
	shipping: Shipping;
	skuPrefix: string;
	shippingPrefix: string;
	css?: string;
	settings: sasagase.GetOrderInstructionSetting[];
}

export const PickingList: React.FC<PickingListProps> = (props) => {
	const { shipping: { orders } } = props;
	if (orders.length > 1) {
		throw new Error();
	}
	const order = orders[0];

	const items: ShippingOrderItemAlpha[] = orders.flatMap(order => order.items)
		.map((item) => {
			const ids = item.ids.map((i) => i.id).filter(Boolean).map((id) => parseInt(id, 10));
			const parentIds = item.ids.map((i) => i.parentId).filter(Boolean).map((id) => parseInt(id, 10));
			return {
				...item,
				id: Math.min(...ids),
				parentId: parentIds.length > 0 ? Math.min(...parentIds) : undefined,
			};
		});
	const parentIds = [...new Set(items.map((item) => item.parentId).filter(Boolean))];
	const parents = items.filter((item) => parentIds.includes(item.id));

	const getParent = (parentId: number): ShippingOrderItemAlpha => {
		const parent = parents.find((p) => p.id === parentId);
		if (!parent) {
			throw new Error("親商品が見つかりません");
		}
		if (parent.parentId) {
			return getParent(parent.parentId);	// 最上位の親を取得
		}
		return parent;
	};

	const groups: ItemGroup[] = [];
	let idx = 1;
	for (const item of items) {
		if (!item.locationId) {
			continue;
		}

		if (item.parentId) {
			const parent = getParent(item.parentId);
			let group = groups.find((group) => group.parent.id === parent.id);
			if (!group) {
				group = { parent, items: [] };
				groups.push(group);
			}
			group.items.push({ ...item, idx: idx++ });
		} else {
			groups.push({ parent: { ...item, idx: idx++ }, items: [] });
		}
	}

	return (
		<div className="page">
			<PickingListHeader shipping={props.shipping} />
			<PickingListShipping shipping={props.shipping} />
			<section className="detail">
				<h1>明細</h1>
				<table>
					<thead>
						<tr>
							<th className="w10 textC">#</th>
							<th className="w100">SKU</th>
							<th>商品名／商品オプション</th>
							<th className="w40">個数</th>
							<th className="w80">備考</th>
						</tr>
					</thead>
					{groups.map((group) =>
						<tbody key={group.parent.id}>
							{group.parent &&
								<ItemListRow itemType="parent" item={group.parent} key={`${group.parent.sku}-parent`} prefix={props.skuPrefix} settings={props.settings} />
							}
							{group.items.map((item, idx) =>
								<ItemListRow itemType="child" item={item} key={`${item.sku}-${idx}`} prefix={props.skuPrefix} settings={props.settings} />
							)}
						</tbody>
					)}
				</table>
				<div className="detailOther">
					<div className="barcode"><Barcode id="barcode" jsbarcode-value={ props.shippingPrefix + props.shipping.id } jsbarcode-height={ 50 }/></div>
					<div className="postage">
						<table>
							<thead>
								<tr>
									<th className="w80">送料</th>
									<th className="w80">代引き手数料</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="textR">{ order.detail.postagePrice ? order.detail.postagePrice.toLocaleString() : '-' } 円</td>
									<td className="textR">{ order.detail.deliveryPrice ? order.detail.deliveryPrice.toLocaleString() : '-' } 円</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</section>
		</div>
	);
};