import dayjs from 'dayjs';
import * as React from 'react';
import { getForShippings as getForShippingsAPI, getShipped as getShippedAPI, getShippingIds as getShippingIdsAPI, getShippings as getShippingsAPI, putShippings } from "../api";
import { useAPI } from "../context";
import { Shipping, ShippingForShipping } from '../types';
import { PromiseWrapper, promiseWrapper } from './promiseWrapper';

export const SHIP_DATE_FORMAT = 'YYYY-MM-DD';

interface ShippingFilterCond {
	shippingState?: string;
	shipDate?: string;	// YYYY-MM-DD
	shippedDate?: string;	// YYYY-MM-DD
	shipMethod?: string;
	wrapping?: string;
	sku?: string;
}

interface UseShippingMethods {
	editState: (checks: Record<string | number, boolean>, shippingState: string) => Promise<boolean>;
	getShippings: (ids: string[]) => Promise<Shipping[]>;
	getForShippings: (checks: Record<string | number, boolean>) => Promise<ShippingForShipping[]>;
	filter: (cond: ShippingFilterCond) => void;
	filterChecks: (checks: Record<string | number, boolean>) => PromiseWrapper<Shipping[]>;
	reload: () => void;
}

// number:出荷予定日(UNIXTIME) => Shipping[]
type SortByDateShipping = Record<number, Shipping[]>;
export interface ShipDateCount {
	shipDate: number;
	count: number;
}
export interface ShipMethodCount {
	shipMethod: string;
	count: number;
}
export interface WrappingCount {
	name: string;
	value: string;
	count: number;
}

export const useShipping = (locationGroupId: number | undefined, initialCond: ShippingFilterCond): [PromiseWrapper<Shipping[]>, ShipDateCount[], ShipMethodCount[], WrappingCount[], UseShippingMethods] => {
	const callAPI = useAPI();

	const initShippingState = 'waiting';
	const todayShipDate = dayjs().startOf('day').valueOf();
	const currentMonthShippedDate = dayjs().startOf('month').valueOf();
	const initSortByDateShipping: SortByDateShipping = {};

	const [count, setCount] = React.useState<number>(0);
	const [shippingState, setShippingState] = React.useState<string>(initialCond.shippingState ?? initShippingState);
	const [shipDate, setShipDate] = React.useState<number>(initialCond.shipDate ? dayjs(initialCond.shipDate).startOf('day').valueOf() : todayShipDate);
	const [shippedMonth, setShippedMonth] = React.useState<number>(initialCond.shippedDate ? dayjs(initialCond.shippedDate).startOf('month').valueOf() : currentMonthShippedDate);
	const [shippings, setShippings] = React.useState<SortByDateShipping>(initSortByDateShipping);
	const [filtered, setFiltered] = React.useState<Promise<Shipping[]>>(Promise.resolve([]));
	const [shipMethodSummary, setShipMethodSummary] = React.useState<ShipMethodCount[]>([]);
	const [wrappingSummary, setWrappingSummary] = React.useState<WrappingCount[]>([]);
	const [shippedShippings, setShippedShippings] = React.useState<Shipping[]>([]);

	React.useEffect(() => {
		if (!locationGroupId) {
			return;
		}

		const load = async () => {
			const callApiMethod = {
				waiting: getShippingsAPI(locationGroupId, shippingState),
				shipping: getShippingsAPI(locationGroupId, shippingState),
				shipped: getShippedAPI(locationGroupId, shippingState, shippedMonth),
			}[shippingState] ?? getShippingsAPI(locationGroupId, shippingState);

			const result = await callAPI(callApiMethod);
			const ss: SortByDateShipping = result.data;
			setShippings(ss);

			// 出荷済み：取得したもの（出荷完了月を指定したもの）をすべて表示
			// 出荷待ち・出荷作業中：出荷予定日ごとの情報を表示
			let ret;
			if (shippingState === 'shipped') {
				ret = Object.values(ss).flat();
				crateFilterShipMethod(ret);
				createFilterWrapping(ret);
				setShippedShippings(ret);
			} else {
				ret = ss[shipDate];
				if (ret) {
					crateFilterShipMethod(ret);
					createFilterWrapping(ret);
				}
			}
			return ret ?? [];
		};

		setFiltered(load());

	}, [shippingState, count]);

	const editState = async (checks: Record<string | number, boolean>, shippingState: string) => {
		try {
			// ステータスの変更が必要なshippingIdに絞り込む
			const editShippingIds = Object.values(shippings).flat()
				.filter(ss => ss.shippingState !== shippingState).map(ss => ss.id);
			const params = Object.entries(checks).filter(([id, check]) => check && id)
				.filter(([id]) => editShippingIds.includes(parseInt(id, 10)))
				.map(([id]) => ({ id: parseInt(id, 10), attributes: { state: shippingState } }));

			if (params.length) {
				await callAPI(putShippings(params));
			}
			setShippingState(shippingState);
			return true;
		} catch (err) {
			console.log(err);
			return false;
		}
	};

	const getShippings = async (ids: string[]) => {
		if (!locationGroupId) {
			return [];
		}

		const result = await callAPI(getShippingIdsAPI(locationGroupId, ids));
		return result.data;
	};

	const getForShippings = async (checks: Record<string | number, boolean>) => {
		const ids = Object.entries(checks).filter(([id, check]) => check && id).map(([id]) => id);
		const result = await callAPI(getForShippingsAPI(ids));
		return result.data;
	};

	const filter = (cond: ShippingFilterCond) => {
		const condShipDate = cond.shipDate ? dayjs(cond.shipDate).startOf('day').valueOf() : todayShipDate;
		const condShippedDate = cond.shippedDate ? dayjs(cond.shippedDate).startOf('month').valueOf() : currentMonthShippedDate;

		// 出荷ステータスの変更があれば再取得
		if ((cond.shippingState ?? initShippingState) !== shippingState) {
			setShippings({ ...initSortByDateShipping });
			setShippingState(cond.shippingState ?? initShippingState);
			setShipDate(condShipDate);
			setShippedMonth(condShippedDate);
			setShipMethodSummary([]);
			setWrappingSummary([]);
		} else if (condShippedDate !== shippedMonth) {
			// 出荷済み＞出荷完了月の変更は再取得
			setShippedMonth(condShippedDate);
			setCount(prev => prev + 1);
		} else if (condShipDate !== shipDate) {
			// 出荷待ち,出荷作業中＞出荷予定日の変更のみであれば取得済みのデータから抽出
			setShipDate(condShipDate);
			let list = shippings[condShipDate] ?? [];
			if (shippings[condShipDate]) {
				crateFilterShipMethod(list);
				list = applyFilters(list, cond);
			}
			setFiltered(Promise.resolve(list));
		} else {
			// 出荷済み
			if (shippingState === 'shipped') {
				const list = applyFilters(shippedShippings, cond);
				setFiltered(Promise.resolve(list));
			} else {
				const list = applyFilters(shippings[condShipDate] ?? [], cond);
				setFiltered(Promise.resolve(list));
			}
		}
	};

	const isWrapping = (detail: Record<string, any>) => {
		return detail.noshiAddress || detail.noshiName || detail.wrapping ? true : false;
	};


	const crateFilterShipMethod = (list: Shipping[]) => {
		// 配送方法のフィルタ
		const shipMethodSummary = list.reduce((acc, shipping) => {
			const method = shipping.method.trim() === '' ? 'なし' : shipping.method;
			const existingItem = acc.find(item => item.shipMethod === method);
			if (existingItem) {
				existingItem.count += 1;
			} else {
				acc.push({ shipMethod: method, count: 1 });
			}
			return acc;
		}, [] as ShipMethodCount[]);
		setShipMethodSummary(shipMethodSummary);
	};

	const createFilterWrapping = (list: Shipping[]) => {
		// 熨斗・ラッピングのフィルタ
		const wrappingCount = list.filter(shipping => shipping.orders.some(order => isWrapping(order.detail))).length;
		setWrappingSummary([
			{ name: 'すべて', value: '', count: list.length },
			{ name: '熨斗・ラッピングあり', value: 'wrapping', count: wrappingCount },
			{ name: '熨斗・ラッピングなし', value: 'nothing', count: list.length - wrappingCount },
		]);
	};

	const applyFilters = (list: Shipping[], cond: ShippingFilterCond) => {
		let filteredList = list;
		// 配送方法
		if (cond.shipMethod) {
			filteredList = filteredList.filter(shipping => {
				const method = shipping.method.trim() === '' ? 'なし' : shipping.method;
				return method === cond.shipMethod;
			});
		}

		createFilterWrapping(filteredList);

		// 熨斗・ラッピング
		if (cond.wrapping) {
			if (cond.wrapping === 'wrapping') {
				filteredList = filteredList.filter(shipping => shipping.orders.some(order => isWrapping(order.detail)));
			} else if (cond.wrapping === 'nothing') {
				filteredList = filteredList.filter(shipping => shipping.orders.some(order => !isWrapping(order.detail)));
			}
		}

		if (cond.sku) {
			const skus = cond.sku.split(",");
			filteredList = filteredList.filter(shipping => shipping.orders.some(order => order.items.some(item => skus.includes(item.sku))));
		}
		return filteredList;
	};

	const filterChecks = (checks: Record<string | number, boolean>) => {
		const promise = async () => {
			const ids = Object.entries(checks).filter(([id, check]) => check && id).map(([id]) => id);
			const shippingAll = Object.values(shippings).flatMap(ss => ss);
			const target = shippingAll.filter(shipping => ids.includes(String(shipping.id)))
			return target;
		};
		return promiseWrapper(promise());
	};

	const reload = () => {
		setCount(prev => prev + 1);
	};

	const shipDateSummary = Object.entries(shippings).map(([shipDate, shippings]) => ({ shipDate: parseInt(shipDate, 10), count: shippings.length})).sort((a, b) => dayjs(a.shipDate).valueOf() - dayjs(b.shipDate).valueOf());

	return [
		promiseWrapper(filtered),
		shipDateSummary,
		shipMethodSummary,
		wrappingSummary,
		{
			editState,
			getShippings,
			getForShippings,
			filter,
			filterChecks,
			reload,
		}
	];
};