import * as React from 'react';
import { useFormContext } from 'react-hook-form';

interface SelectProps {
	name: string;
	options?: Record<string, string>;
	children?: React.ReactNode;
	rules?: Record<string, unknown>;
	className?: string;
	defaultValue?: string;
	disabled?: boolean;
	onChange?: (ev: React.ChangeEvent<HTMLSelectElement>) => void;
}
export const Select: React.FC<SelectProps> = (props) => {
	const { register } = useFormContext();

	const { name, rules, onChange, ...inputProps } = props;

	return (
		<>
			<select className={props.className}
				{...inputProps}
				{...register(name, rules ? { ...rules, onChange } : { onChange })} >
				{props.children &&
					props.children
				}
				{props.options && <>
					{Object.entries(props.options).map(([key, value]) =>
						<option key={key} value={key} disabled={!key} hidden={!key}>{value}</option>
					)}
				</>}
			</select>
		</>
	);
}
export default Select;
