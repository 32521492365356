import { APIRequest } from '.';

export function getInvoice(names: string[]): APIRequest {
	const params = new URLSearchParams();
	names.forEach(name => params.append('name', name));
	return {
		method: 'get',
		url: `/api/store/invoice?` + params.toString(),
	};
}

export function getBarcode(): APIRequest {
	return {
		method: 'get',
		url: `/api/store/barcode`,
	};
}

export function getOrderInstructionSettings(): APIRequest {
	const params = new URLSearchParams();
	params.append('name', 'orderInstructionSettings');
	return {
		method: 'get',
		url: `/api/store/workingData?` + params.toString(),
	};
}