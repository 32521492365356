import dayjs from 'dayjs';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { SHIPPING_FILTER_SKU_SELECT, SHIPPING_STATE as SHIPPING_STATE_BASE } from '../../../const';
import { useListSelector, useLocationGroup, useShipping } from '../../../hooks';
import { SHIP_DATE_FORMAT } from '../../../hooks/useShipping';
import { Button } from '../../atoms/Button';
import { Overlay } from '../../molecules/Overlay';
import { PageBlock } from '../../molecules/PageBlock';
import { PageMain } from '../../molecules/PageMain';
import { ShipDateLabel } from '../../molecules/ShipDateLabel';
import { Form, Input, InputRadio, Select } from '../../organisms/Form';
import { ShippingListTable } from './ShippingListTable';
import { ShippingStart } from './ShippingStart';
import ShippingCancel from './ShippingCancel';

// このページでは保留のステータスは扱わないので除外する
const SHIPPING_STATE = Object.fromEntries(Object.entries(SHIPPING_STATE_BASE).filter(([k]) => k !== 'pending'));

const initValues = {
	shippingState: 'waiting',
	shipDate: dayjs().format(SHIP_DATE_FORMAT),
	shippedDate: dayjs().startOf('month').format(SHIP_DATE_FORMAT),
	sheet: '',
	logistics: '',
	shipMethod: '',
	wrapping: '',
	filterSku: '',
	sku: '',
	selectSku: ''
}
type FormValues = typeof initValues;

export const ShippingList: React.FC = () => {
	const [overlay, setOverlay] = React.useState<string>('');
	const formMethods = useForm<FormValues>({
		defaultValues: initValues,
	});
	const { getValues, watch, setValue } = formMethods;
	const { getChecks, checkedNum, reset } = useListSelector();
	const [locationGroup] = useLocationGroup();
	const [shippingsPomise, shipDateSummary, shipMethodSummary, wrappingSummary, shippingMethods] = useShipping(locationGroup?.id, initValues);
	const [disableSku, setDisableSku] = React.useState(true);

	const applyShippingFilter = (customValues = {}) => {
		const { shippingState, shipDate, shippedDate, shipMethod, wrapping, sku } = getValues();

		const filterParams = {
			shippingState,
			shipDate,
			shippedDate,
			shipMethod,
			wrapping,
			sku,
			...customValues,
		};

		shippingMethods.filter(filterParams);
		reset({});
	};

	const handleClickFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (["shipDate", "shippedDate"].includes(e.currentTarget.name)) {
			setValue("shipMethod", initValues.shipMethod);
			setValue("wrapping", initValues.wrapping);
			setValue("filterSku", initValues.filterSku);
			setValue("sku", initValues.sku);
			setDisableSku(true);
		}
		if (e.currentTarget.name === "shipMethod") {
			setValue("wrapping", initValues.wrapping);
			setValue("filterSku", initValues.filterSku);
			setValue("sku", initValues.sku);
			setDisableSku(true);
		}
		const customValues = {
			[e.currentTarget.name]: e.currentTarget.value
		};
		applyShippingFilter(customValues);
	};

	const handleClickShippingStart = async () => {
		const shippingStartState = 'shipping';
		if (checkedNum() <= 0) {
			return;
		}

		const result = await shippingMethods.editState(getChecks(), shippingStartState);
		if (!result) {
			return;
		}
		setValue('shippingState', shippingStartState);

		setOverlay('shippingStart');
	};
	const handleClickShippingCancel = async () => {
		if (checkedNum() <= 0) {
			return;
		}
		setOverlay('shippingCancel');
	};
	const handleShippingCancelDone = async () => {
		shippingMethods.reload();
		setOverlay('');
	};

	const handleGetForShippings = async () => {
		return await shippingMethods.getForShippings(getChecks());
	};

	const handleClickFilterSku = () => {
		const { filterSku } = getValues();
		if (filterSku === '') {
			setDisableSku(true);
			setValue("sku", initValues.sku);
			applyShippingFilter();
		} else {
			setDisableSku(false);
		}
	};

	const handleClickSelectSku = () => {
		const { sku, selectSku } = getValues();
		const skus = sku.split(",").filter(Boolean);
		if (selectSku && !skus.includes(selectSku)) {
			skus.push(selectSku);
		}
		setValue("sku", skus.join(","));
		setValue("selectSku", initValues.selectSku);
		applyShippingFilter();

	};

	// 出荷済みの出荷完了月(直近3ヶ月)の選択肢
	const currentMonth = dayjs().startOf('month');
	const shippedDateSelect = [...new Array(3)].map((_,idx) => currentMonth.subtract(idx, 'month').valueOf()).reverse();

	return (
		<PageMain title="出荷管理">
			<PageBlock>
				<section>
					<Form methods={formMethods}>
						<div className="menu-container">
							<div className="menu-row">
								<div className="menu-col-6">
									出荷ステータス<br />
									<div className="state-selector">
										{Object.entries(SHIPPING_STATE).map(([key, name]) => 
											<InputRadio key={key} name="shippingState" value={key} onChange={handleClickFilter}>{name}</InputRadio>
										)}
									</div>
								</div>
								<div className="menu-col-6">
									{watch('shippingState') !== 'shipped' && <>
										出荷予定日<br />
										<div className="state-selector">
											{shipDateSummary.map(summary =>
												<InputRadio key={summary.shipDate} name="shipDate" value={dayjs(summary.shipDate).format(SHIP_DATE_FORMAT)} onChange={handleClickFilter}><ShipDateLabel shipDateCount={summary} /></InputRadio>
											)}
										</div>
									</>}
									{watch('shippingState') === 'shipped' && <>
										出荷完了月<br />
										<div className="state-selector">
											{shippedDateSelect.map(shippedDate =>
												<InputRadio key={shippedDate} name="shippedDate" value={dayjs(shippedDate).format(SHIP_DATE_FORMAT)} onChange={handleClickFilter}>{`[${dayjs(shippedDate).format('YYYY-MM')}]`}</InputRadio>
											)}
										</div>
									</>}
								</div>
								<div className="menu-col-6">
									配送方法<br />
									<div className="state-selector">
										{shipMethodSummary.length > 0 && <>
											<InputRadio key="all" name="shipMethod" value="">すべて ({shipMethodSummary.map(shipMethod => shipMethod.count).reduce((accumulator, currentValue) => accumulator + currentValue, 0)}件)</InputRadio>
											{shipMethodSummary.map(shipMethod =>
												<InputRadio key={shipMethod.shipMethod} name="shipMethod" value={shipMethod.shipMethod} onChange={handleClickFilter}>{shipMethod.shipMethod}({shipMethod.count}件)</InputRadio>
											)}
										</>}
									</div>
								</div>
								<div className="menu-col-6">
									熨斗・ラッピング<br />
									<div className="state-selector">
										{shipMethodSummary.length > 0 && <>
											{wrappingSummary.map(wrapping =>
												<InputRadio key={wrapping.name} name="wrapping" value={wrapping.value} onChange={handleClickFilter}>{wrapping.name}({wrapping.count}件)</InputRadio>
											)}
										</>}
									</div>
								</div>
								<div className="menu-col-12">
									SKU <br />
									<div className="state-selector">
										<InputRadio key="all" name="filterSku" value="" onChange={handleClickFilterSku}>すべて</InputRadio>
										<InputRadio key="sku" name="filterSku" value="input" onChange={handleClickFilterSku}>SKU指定(カンマ区切りで複数入力可)</InputRadio>
										<Input type="text" name="sku" onChange={handleClickFilter} disabled={disableSku} />
										<Select name="selectSku" disabled={disableSku} onChange={handleClickSelectSku}>
											<option value="">選択肢から追加</option>
											{SHIPPING_FILTER_SKU_SELECT.map(selectSku =>
												<option key={selectSku.sku} value={selectSku.sku}>{selectSku.sku}:{selectSku.name}</option>
											)}
										</Select>
									</div>
								</div>
							</div>
						</div>
						<div className="shippingMenu">
							<div className={`shippingMenuBase is_active`}>
								<div className="row">
									<div className="col">
										<div>出荷作業</div>
										<div>
											<Button onClick={handleClickShippingStart}>出荷作業を行う</Button>
										</div>
									</div>
									<div className="col">
										<div>出荷の戻し</div>
										<div>
											<Button onClick={handleClickShippingCancel}>出荷作業中から戻す</Button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Form>
				</section>
			</PageBlock>
			<PageBlock title="出荷一覧">
				<section>
					<React.Suspense fallback={<p>Loading...</p>}>
						<ShippingListTable shippings={shippingsPomise} locationGroupId={locationGroup?.id} searchShippingState={watch('shippingState')} />
					</React.Suspense>
				</section>
			</PageBlock>
			{overlay === 'shippingStart' &&
				<Overlay onClose={() => setOverlay('')}>
					<ShippingStart
						shippings={shippingMethods.filterChecks(getChecks())}
						reload={shippingMethods.reload}
						getForShippings={handleGetForShippings} />
				</Overlay>
			}
			{overlay === 'shippingCancel' &&
				<Overlay onClose={() => setOverlay('')}>
					<ShippingCancel
						shippings={shippingMethods.filterChecks(getChecks())}
						onDone={handleShippingCancelDone}
						onClose={() => setOverlay('')}
					/>
				</Overlay>
			}
		</PageMain>
	);
}
export default ShippingList;